<template>
  <div>
    <solution-header
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :call-us-btn="callUsBtn"
        :title="title"
    />
<!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`行业背景和行业痛点`"
               :en_title="`Industry Background & Industry Pain Points`"></Paragraph>
    <div style="color: #969696;font-size: 1.4rem;text-align: center;width: 80%;
       margin-left: 10%;
      font-weight: 400;">
      农业数字化进程中存在很多问题亟需解决。围绕着数字农业的关键要素——数据，存在以下痛点：</div>

    <Logotitlebox :datalist="datalist"></Logotitlebox>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`平台优势`"
               :en_title="`Advantage Of The Platform `"></Paragraph>
    <div style="width: 80%;margin-left: 10%">
      <Lettertitleblock :datalist="datalistx"
                        style="display: flex;flex-direction: row;justify-content:center ;
                        width: 100%"></Lettertitleblock>
    </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
               :title="`方案价值`"
               :en_title="`Value Of The Program`"></Paragraph>
    <Titlestrlist :datalist="datalistF" style="margin-bottom: 6rem"></Titlestrlist>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`应用场景与案例`"
               :en_title="`Application Scenarios & Cases`"></Paragraph>
    <div style="display: flex;flex-direction: row;width: 70%;margin-left: 15%;margin-bottom: 6rem">
      <div style="width: 50%;display: flex;flex-direction: row">
        <div style="font-size:1.2rem;padding: 2rem;
        display: flex;flex-direction: column;align-items: center">
        <img src="@/assets/image/solution/farming/green.png" style="width: 8rem;"/>
          数字农业管理平台
        </div>
        <div style="font-size:1.2rem;padding: 2rem;
        display: flex;flex-direction: column;align-items: center">
          <img src="@/assets/image/solution/farming/poper.png" style="width: 8rem;"/>
          供应链管理平台
        </div>
        <div style="font-size:1.2rem;padding: 2rem;
        display: flex;flex-direction: column;align-items: center">
          <img src="@/assets/image/solution/farming/yellow.png" style="width: 8rem;"/>
          农业金融服务平台
        </div>
      </div>
      <div style="display: flex;flex-direction: column;margin-top: -4rem;
      width:50%;justify-content: center;align-items: center">
        <img src="@/assets/image/solution/farming/cloud.png" style="height: 7rem;padding: 2rem"/>
        <img src="@/assets/image/solution/farming/chian.png" style="height: 6.2rem;padding: 2rem"/>
        <img src="@/assets/image/solution/farming/shanghai.png" style="height: 6rem;padding: 2rem"/>
      </div>
    </div>
  </div>
</template>

<script>
import SolutionHeader from '@/components/common/SolutionHeader';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';
import Logotitlebox from '@/components/chain/basic/common/logotitlebox';
import Titlestrlist from '@/components/chain/basic/common/titlenewlinelist';

export default {
  name: 'BaaSLeaflet',
  components: {
    SolutionHeader,
    // ImageList,
    Paragraph,
    Lettertitleblock,
    Logotitlebox,
    Titlestrlist,
  },
  data() {
    return {
      datalistF: [
        { title: '监管部门', content: '实现实时监管、提高监管效率、提高监管效果' },
        { title: '农业企业', content: '精准农业节能减排、提高管理协同效率、提高产品品质、提升品牌价值' },
        { title: '农服机构', content: '精准研发产品与服务、提升服务效果、降低服务成本' },
        { title: '金融保险', content: '消除信息不对称、建立数据征信机制、建立新型风控模型、降低风控成本' },
      ],
      datalistx: [
        {
          title: '高性能',
          imgsrc: 'A',
          list: [
            'TPS:10000以上',
            '平均出块时间：1S',
          ],
        }, {
          title: '高可用',
          imgsrc: 'B',
          list: [
            '热备切换',
            '节点出错自愈 ',
            '节点流量控制',
            '',
          ],
        }, {
          title: '高安全\n',
          imgsrc: 'C',
          list: [
            '多级加密机制',
            '安全多方计算',
            '',
          ],
        }, {
          title: '可扩展\n',
          imgsrc: 'D',
          list: [
            '数据存储横向扩展',
            '数据库类型替换',
            '一键资产发布',
            '数据通证化',
            '',
          ],
        }, {
          title: '易运维\n',
          imgsrc: 'E',
          list: [
            '合约数据可视化',
            '可视化监控',
            'BaaS平台',
            '',
          ],
        },
      ],
      datalist: [
        {
          imageurl: require('@/assets/image/contentsvg/box.svg'),
          title: '数据收集</br>难度大，成本高',
        }, {
          imageurl: require('@/assets/image/contentsvg/note.svg'),
          title: '数据中心化存储</br>可信度存疑',
        },
        {
          imageurl: require('@/assets/image/contentsvg/tikit.svg'),
          title: '协同成本高</br>安全与隐私问题',
        }, {
          imageurl: require('@/assets/image/contentsvg/tikit2.svg'),
          title: '数据无法</br>交易变现',
        }, {
          imageurl: require('@/assets/image/contentsvg/star.svg'),
          title: '数据无法</br>充分利用',
        },
      ],
      title: '区块链+农业解决方案',
      body:
          '建设基于农业产业链的行业联盟链，破解农业产业链信息不对称现状，'
          + '在保护隐私的前提下实现农业产业可信数据高效流通和价值共享，实现数据商业价值，重塑社会互信机制。',
      image: require('@/assets/image/solution/farming/head.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/solution/farming/24_01.jpg'),
        require('@/assets/image/solution/farming/24_02.jpg'),
        require('@/assets/image/solution/farming/24_03.jpg'),
        require('@/assets/image/solution/farming/24_04.jpg'),
      ],
    };
  },
};
</script>

<style scoped>
</style>
